<div class="container">
    <div class="column"><img alt="privatefan logo" src="./assets/images/logo/privatefan24.png"></div>
    <div class="column"><strong>{{'Language' | translate}}</strong>&nbsp;<app-select-language></app-select-language></div>
</div>
<div class="container">
    <div class="column"><h1>{{'Welcome' | translate}}</h1></div>
</div>
<div class="container">
    <div class="column">
        <app-comp-a></app-comp-a>
        <app-comp-b></app-comp-b>
    </div>
</div>
